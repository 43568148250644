import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Navigation.css'

const Navigation=() =>{
  
  return (
    <div>   <Navbar  className='navbar-head'  expand="lg" >
    <Container>
      <Navbar.Brand href="#"className='title' >Next Nest Technologies</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav className="ms-auto nav-items">
          <Nav.Link href="#home"className="nav-item" >Home</Nav.Link>
          <Nav.Link href="#about" className="nav-item">About</Nav.Link>
          <Nav.Link href="#services" className="nav-item">Services</Nav.Link>
          <Nav.Link href="#contact" className="nav-item">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar></div>
  )
}

export default Navigation