import React from 'react'
import { Container, Row, Col,Card } from "react-bootstrap";
import "./About.css";

const About = () => {
  return (
    <div id="about">
      <div className="py-5">
        <Container>
          <Row className="justify-content-between mt-5 about-info text-center">
            <Col lg="12" className="main-container">
              <h1 className="text-center py-1">
                ABOUT
                <span> US</span>
              </h1>
              Next Nest Technologies Pvt. Ltd. with a vision to serve innovative technology in service, training and product development with multiple Businesses.
              Next Nest Technologies Pvt. Ltd. Providing multi-dimensional solutions using our skillset, industrial best practices and talented resources with good business models. Has a capacity and capability to understand the client requirements to deliver a better quality service as well as products.
            </Col>
            </Row>
            <Row className="justify-content-between mt-5 about-info text-center">
            <Col lg="6" md="6">
            <Card style={{ width: '100%', height:"100%",border: '0', borderRadius: '5'}} className='card-container'>
            <Card.Body>
              <Card.Title className='card-title' >MISSION</Card.Title>
              <Card.Text className='card-description'>
              We strive to meet the expectations of our customers by understanding their requirements using our knowledge to break barriers to achieve their goal.
             </Card.Text>
            
            </Card.Body>
          </Card>
          </Col>
          <Col lg="6" md="6">
            <Card style={{ width: '100%', height:"100%",border: '0', borderRadius: '5'}} className='card-container'>
            <Card.Body>
              <Card.Title className='card-title' >VISION</Card.Title>
              <Card.Text className='card-description'>
              Our vision is to provide good customer service and quality products to the market. We strongly believe in our commitment to service for the growth segment of customers. We believe in precision and high quality work. We are happy to work and want to be well placed in IT field.
              </Card.Text>
            </Card.Body>
           </Card>
            </Col>
          </Row>
        </Container>
      </div>

  </div>
  )
}

export default About