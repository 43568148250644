import React from 'react'
import waveImage from "../assests/wave.svg"
import Image from 'react-bootstrap/Image';
import "./Home.css"

const Home = () => {
  return (
    <div className='home' id='home'>
        <div className='home-bg'>
        <div>
        <h1>Explore new possibilities with us</h1>
         <h5 >If you want to explore new possibilities to your esteemed company...</h5>
        </div>
        </div>
         <Image className='image' src={waveImage}  /> 
    </div>
  )
}

export default Home