import React from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import "./Footer.css";

import location from "../assests/location.svg";
import phone from "../assests/phone.svg";
import mail from "../assests/mail.svg";
import instagram from "../assests/instagram.svg";
import facebook from "../assests/facebook.svg";
import linkedIn from "../assests/linkedIn.svg";
import twitter from "../assests/twitter.svg";

const Footer = () => {
  return (
    <div id="contact">
      {" "}
      <div className="footer-container">
        <Container className="mt-5 mb-5">
          <Row>
            <Col lg={3} md={3} sm={6}>
              <h5>Useful Links</h5>
              <div>
                {" "}
                <a href="#home">Home</a>{" "}
              </div>
              <div>
                <a href="#about">About</a>
              </div>
              <div>
                <a href="#services">Services</a>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4}>
              <h5>Services</h5>
              <div>Web Design</div>
              <div>Web Development</div>
              <div>Mobile App Development</div>
              <div>Software Development</div>
              <div>CAE Simulations</div>
            </Col>
            {/* <Col lg={3} md={3} sm={3}>
              <h5>Our Products</h5>
              <div>CRM</div>
              <div>E-commerce</div>
              <div>Tours and Travels application</div>
            </Col> */}
            <Col lg={5} md={5} sm={4}>
              <h5>Contact </h5>
              <Row className="pt-2">
                <Col lg="1" md="1">
                  <Image className="img" src={location} alt="" />
                </Col>
                <Col className="info">
                  <div>
                    Plot 17, Suhag Colony, Bagalkot Road, Bijapur, Karnataka,
                    India- 586101
                  </div>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col lg="1" md="1">
                  <Image className="img" src={phone} />
                </Col>
                <Col className="info">
                  <div>+91 9148086078</div>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col lg="1" md="1">
                  <Image className="img" src={mail} alt="" />
                </Col>
                <Col className="info">
                  <div>support@nextnesttech.com</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="footer-content">
          <Container className="d-flex align-items-center justify-content-between">
            <div className="text-white">
              Copyright © 2022 Next Nest Technologies Pvt Ltd - All rights
              reserved
            </div>
            <div>
              <Image src={instagram} />
              <Image src={facebook} />
              <Image src={twitter} />
              <Image src={linkedIn} />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Footer;
