import React from "react";
import { Container, Image } from "react-bootstrap";
import "./TechStack.css";

import html from "../assests/techstack/HTML.png";
import css from "../assests/techstack/CSS.png";
import bootstrap from "../assests/techstack/Bootstrap.png";
import nodejs from "../assests/techstack/Node js.png";
import python from "../assests/techstack/Python.png";
import react from "../assests/techstack/React-icon.svg.png";
import git from "../assests/techstack/gitlab-icon.png";
import mongoDB from "../assests/techstack/mongodb.png";
import javascript from "../assests/techstack/Javscript.png";

const TechStack = () => {
  return (
    <div id="techstack">
      <div className=" py-5">
        <Container>
          <h1>Our Tech Stack</h1>

          <div className="py-2 sub-container">
            <div>
              <Image className="img" src={html} alt="" />
            </div>
            <div>
              <Image className="img" src={css} alt="" />
            </div>
            <div>
              <Image className="img" src={bootstrap} alt="" />
            </div>
            <div>
              <Image className="img" src={javascript} alt="" />
            </div>
            <div>
              <Image className="img" src={react} alt="" />
            </div>
            <div>
              <Image className="img" src={nodejs} alt="" />
            </div>
            <div>
              <Image className="img" src={mongoDB} alt="" />
            </div>
            <div>
              <Image className="img" src={python} alt="" />
            </div>
            <div>
              <Image className="img" src={git} alt="" />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default TechStack;
