import "./App.css";
import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navigation";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Careers from "./components/Careers";
import TechStack from "./components/TechStack";

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <Home></Home>
      <Services></Services>
      <About></About>
      <TechStack></TechStack>
      <Careers></Careers>
      <Footer></Footer>
    </div>
  );
}

export default App;
