import React from "react";
import { Container } from "react-bootstrap";
import "./Careers.css";
const Careers = () => {
  return (
    <div id="career">
      <div className=" py-5">
        <Container>
          <h5>Embark on a new chapter in your professional journey with us!</h5>
          <p>
            {" "}
            For internship opportunities, kindly submit your resume to{" "}
            <b>"hr@nextnesttech.com"</b>
          </p>
        </Container>
      </div>
    </div>
  );
};

export default Careers;
