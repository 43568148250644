import React from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import "./Services.css";
import uiux from "../assests/development.svg";
import mobile from "../assests/mobile.svg";
import laptop from "../assests/laptop.svg";
import ai from "../assests/artificial-intelligence.svg";
import carengine from "../assests/car-engine.svg";

const Services = () => {
  const serviceInfo = [
    {
      image: uiux,
      title: "UI/UX Design",
      description:
        "Our UI/UX team is very creative in product development. What you visioned that we will help you to make into reality. We have matured designing team and we rely on established standards and Hanson expertise hands-on expertise..",
    },
    {
      image: mobile,
      title: "Mobile App Development",
      description:
        "When you want to achieve productivity, mobile apps will come in very handy, Our custom mobile application development service will help you to meet your deadlines without any extra hassle.",
    },
    {
      image: laptop,
      title: "Web Design and Development",
      description:
        "Web sites are the first step to put the impression of our business proposals . We design your thoughts , we develop your content and our web development team will ensures you with latest design and development techniques.",
    },
    {
      image: ai,
      title: "Artificial Intelligence",
      description:
        "We understand the behaviour of the data and our artificial intelligence research team is very hands-on in analyzing and recognizing the pattern of any kind of data. Our AI team will help to take the right decision at right time. Experience the power of AI.",
    },
    {
      image: carengine,
      title: "CAE Simulations",
      description:
        "The NNT specializes in engineering services, excelling in numerical calculation and simulation(CAE). They provide development process solutions and software, with expertise in serving OEM clients in the Automotive, Commercial Vechicles, and Aerospace industries.",
    },
  ];

  return (
    <div className="services pt-5" id="services">
      <dim className="mt-5">
        <h1>What we do...</h1>
        <p>
          We are pioneer in building technical solutions for real world problem.
          We develop software products, and provide services to esteemed
          organization for ease of their business transactions.
        </p>
      </dim>

      <Row xs={1} md={2} lg={4} className="g-4">
        {serviceInfo.map((val) => {
          return (
            <Col>
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0",
                  borderRadius: "5",
                }}
                className="card-container"
              >
                <div className="image-container">
                  <Image className="icons" src={val.image}></Image>
                </div>
                <Card.Body>
                  <Card.Title className="card-title">{val.title}</Card.Title>
                  <Card.Text className="card-description">
                    {val.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Services;
